import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const ListStores = ({ data }) => {
  const listStore = data;
  return (
    <div className="omg-list-store-wrapper ptb-3rem">
      <div className="container">
        <div className="omg-list-store">
          <div className="auto-rem">
            <h2 className="text-center display-2">{listStore.title}</h2>
            <p className="body-lg text-center">{listStore.subtitle}</p>
          </div>
          <div className="omg-card">
            <div className="omg-list-store-inner row">
              {listStore.image.map((item, index) => (
                <div className="col-2" key={index}>
                  <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                    <img
                      src={`/images/${item}`}
                      className="optimize-img"
                      alt="shopify store"
                      key={index}
                    />
                  </ScrollAnimation>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* <AmazingLearning /> */}
      </div>
    </div>
  );
};

export default ListStores;
