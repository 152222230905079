import React from "react";

const CircleE = () => {
    return (
        <svg
            width="47"
            height="47"
            viewBox="0 0 47 47"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="23.5" cy="23.5" r="23.5" fill="#E0E0E0" />
        </svg>
    );
};

export default CircleE;
