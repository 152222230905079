import React, { useState, useEffect, useRef } from "react";
import ReviewStar from "../SVGS/icons/ReviewStar";
import ScrollAnimation from "react-animate-on-scroll";

const CustomerReview = ({ data }) => {
  const { title, description, content } = data;
  return (
    <div className="omg-happy-customer-wrapper ptb-3rem">
      <div className="container">
        <div className="auto-rem">
          <h1 className="display-2 text-center">{title}</h1>
          <h2 className="text-center body-lg">{description}</h2>
        </div>
        <div className="row">
          {content.map((item, index) => (
            <div className="col-md-4" key={index}>
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className="omg-card-review text-center " key={index}>
                  <h3 className="st-h5">{item.name}</h3>
                  <i>{item.country}</i>
                  <div>
                    <ReviewStar />
                  </div>
                  <p className="body-lg">{item.comment}</p>
                </div>
              </ScrollAnimation>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomerReview;
