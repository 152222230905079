import React from "react";
import BannerFooter from "./common/BannerFooter";
import HeroBanner from "./common/HeroBanner";
import HowItWork from "./common/HowItWork";

import ListStores from "./common/ListStores";
import Platform from "./common/Platform";
import PricingTable from "./common/PricingTable";
import TheNewWay from "./common/TheNewWay";
import { renderBackground } from "utils";
import HappyCustomers from "./common/HappyCustomers";
import CustomerReview from "./common/CustomerReview";
import GetReady from "./common/GetReady";
import ContactUs from "./common/ContactUs";
import SaleBanner from "./common/SaleBanner";
import Features from "./common/Features";

const HomePage = ({ data }) => {
  const { home, review } = data;
  const {
    heroBanner,
    listStores,
    pricing,
    ecommerces,
    theNewWay,
    setUp,
    happyCustomers,
    customerReview,
  } = home;

  const components = [
    <HeroBanner data={[heroBanner, ecommerces]} />,
    // <SaleBanner />,
    <Features data={listStores} />,
    <HowItWork data={setUp} />,
    <TheNewWay data={theNewWay} />,
    <PricingTable data={pricing} />,
    // <Platform data={ecommerces} />,
    <ListStores data={listStores} />,
    <CustomerReview data={customerReview} />,
    // <HappyCustomers data={happyCustomers} />,
    <GetReady />,
    // <ContactUs />
  ];

  return (
    <article style={{ overflow: "hidden" }}>
      {renderBackground(components)}
    </article>
  );
};

export default HomePage;
