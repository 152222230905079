import React, { useState, useEffect } from 'react';
import Auto from '../SVGS/icons/Auto';
import FlashA from '../SVGS/icons/FlashA';
import Gateway from '../SVGS/icons/Gateway';
import Lighting from '../SVGS/icons/Lighting';
import Note from '../SVGS/icons/Note';
import Store from '../SVGS/icons/Store';
import Unlimited from '../SVGS/icons/Unlimited';
import ScrollAnimation from 'react-animate-on-scroll';
import Image from 'next/future/image';

const TheNewWay = ({ data }) => {
  const [show, setShow] = useState(0);
  const theNewWay = data.content;
  const icons = [
    <Lighting />,
    <FlashA />,
    <Unlimited />,
    <Store />,
    <Gateway />,
    <Auto />,
    <Note />,
  ];
  useEffect(() => {});
  return (
    <div className="omg-the-new-way-wrapper ptb-3rem">
      <div className="container">
        <h2 className="display-2 text-center auto-rem">{data.title}</h2>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <div className="row omg-new-way-inner">
            <div className="col-md-6">
              {theNewWay.map((item, index) => (
                <div className="omg-new-way-img" key={index}>
                  {index == show ? (
                    <Image
                      src={`/images/${item.image}`}
                      alt="the new way"
                      sizes="(max-width: 768px) 100vw, 636px"
                      width={636}
                      height={636}
                      style={{
                        animation: 'fadeIn 1s',
                      }}
                      className="optimize-img"
                    />
                  ) : null}
                </div>
              ))}
            </div>
            <div className="col-md-6">
              {theNewWay.map((item, index) => (
                <div
                  className={
                    index == show
                      ? `omg-new-way-content new-way-active`
                      : `omg-new-way-content`
                  }
                  key={index}
                  onClick={() => setShow(index)}
                  id={`new-way-position${index}`}
                >
                  <div className="new-way-title">
                    <h3 className="st-h6">
                      {icons[index]} {item.title}
                    </h3>
                  </div>
                  {index === show ? (
                    <div
                      className=""
                      style={{
                        maxHeight: '30rem',
                        transition: 'max-height 1s ease',
                        overflow: 'hidden',
                      }}
                    >
                      {item.text.map((text, index) => (
                        <div key={index}>
                          <p className="body-xs">{text}</p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div
                      className=""
                      style={{
                        maxHeight: '0rem',
                        transition: '1s',
                        overflow: 'hidden',
                      }}
                    >
                      {item.text.map((text, index) => (
                        <div style={{ display: 'block' }} key={index}>
                          <p className="body-xs">{text}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default TheNewWay;
