import React from "react";

const TagBlue = () => {
    return (
        <svg
            width="57"
            height="57"
            viewBox="0 0 57 57"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M30.4349 7.5C28.5784 7.5 26.7979 8.2375 25.4851 9.55025L8.87855 26.1568C7.05611 27.9793 7.0561 30.934 8.87855 32.7565L24.2454 48.1233C26.0678 49.9458 29.0226 49.9458 30.8451 48.1233L47.4516 31.5167C48.7644 30.204 49.5019 28.4235 49.5019 26.567V12.1667C49.5019 9.58934 47.4126 7.5 44.8352 7.5H30.4349Z"
                fill="#abdaf0"
            />
            <path
                d="M36.4656 42.5002L14.4991 20.5337L11.1992 23.8335L33.1657 45.8L36.4656 42.5002Z"
                fill="#006eba"
            />
            <path
                d="M40.1667 19.1668C40.1667 20.4555 39.122 21.5002 37.8333 21.5002C36.5447 21.5002 35.5 20.4555 35.5 19.1668C35.5 17.8782 36.5447 16.8335 37.8333 16.8335C39.122 16.8335 40.1667 17.8782 40.1667 19.1668Z"
                fill="#006eba"
            />
        </svg>
    );
};

export default TagBlue;
