import { Autoplay, Navigation, Pagination } from "swiper";
import secondslideright from "../../public/images/homepage/secondslideright.png";
import secondslidemobile from "../../public/images/homepage/secondslidemobile.png";
import { actions, useStore } from "../../store";
import Falcon from "../SVGS/icons/Falcon";
import silde_Banner from "../../public/images/homepage/silde_Banner.png";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import Image from "next/future/image";
import bannerDone from "../../public/images/homepage/bannerDone.png";
import decor2 from "../../public/images/homepage/decor2.png";

const HeroBanner = ({ data }) => {
  const [heroBanner, ecommerces] = data;
  const [state, dispatch] = useStore();
  const url =
    "https://docs.google.com/forms/d/e/1FAIpQLSfgUb4t9h8d_eZN5VTL7M1yJvAOF6gVmUYWD9VnBEFtEvi9BQ/viewform";
  const urlLeanMore = "https://platform.shoffi.app/login";
  const urlJoin =
    "https://platform.shoffi.app/signup/affiliate?app=Il_VwAn8snq ";
  const mainMarkup = (
    <div className="omg-hero-banner-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 banner-content">
            <div className="row omg-hero-banner">
              <div className="omg-hero-banner-content">
                <div className="col">
                  <h2 className="typewriter">
                    <span className="st-h5 hero-subtitle">
                      {heroBanner.subtitle}
                    </span>
                  </h2>
                  <h1 className="hero-title">
                    Auto-Sync Stripe & PayPal Tracking Info for Faster Funds
                  </h1>
                  <p className="body-lg">{heroBanner.content}</p>
                  <p className="pink-bg">
                    <img
                      src="/images/homepage/diamon.png"
                      style={{ width: "16px", marginRight: "4px" }}
                    />
                    Built for Shopify
                  </p>
                  <button
                    className="btn-main"
                    onClick={() => {
                      window.open(
                        "https://apps.shopify.com/synctrack?utm_source=synctrackio&utm_medium=herobanner&utm_campaign=website",
                        "_blank"
                      );
                      // dispatch(actions.setModal(true));
                      GAEventsTracker("Button/click", `${heroBanner.button}`);
                    }}
                  >
                    <Falcon />
                    {heroBanner.button}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <Image
              src={bannerDone}
              sizes="(max-width: 768px) 100vw, 890px"
              width={890}
              height={757}
              alt="hero banner synctrack app"
              className="optimize-img"
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginBottom: "2rem",
          }}
        >
          {ecommerces.content.map((item, index) => (
            <div
              key={index}
              className="col-md-3"
              style={{ marginRight: "1rem" }}
            >
              <a href={item.href} target="_blank" rel="noopener">
                <img
                  src={`/images/${item.image}`}
                  alt={item.alt}
                  style={{ maxWidth: "150px", maxHeight: "45px" }}
                  className="optimize-img"
                />
              </a>
            </div>
          ))}
        </div>
      </div>
      <div className="omg-clip-path">
        <Image
          src={decor2}
          sizes="(max-width: 768px) 100vw, 950px"
          width={950}
          height={600}
          alt="decor"
          className="optimize-img"
        />
        <div className="background-blur"></div>
      </div>
      <div className="background__text">
        <span>Fast. Feasible & Flexible</span>
      </div>
    </div>
  );

  const secondMarkup = (
    <div className="second-banner-wrapper">
      <div className="container second-banner">
        <div className="row">
          <div className="col-lg-6 banner-content">
            <div className=" omg-hero-banner-content">
              <span className="banner-text-1">Become a </span>
              <b className="banner-text-1">Synctrack Affiliate</b>
              <h1 className="banner-text-2">
                {" "}
                REFER <span>&</span> EARN 20%{" "}
              </h1>
              <h2 className="banner-text-3">for every subsciption sale</h2>
              <span className="banner-text-4">Sharing is rewarding!</span>
              <div className="button-decor btn-3">
                <div>
                  <button
                    className="btn-main btn-1"
                    onClick={() => {
                      GAEventsTracker(
                        "Button/click",
                        `affiliate_join_now_home_banner`
                      );
                      window.open(urlJoin, "_blank", "noopener,noreferrer");
                    }}
                  >
                    <span>Join now </span>
                  </button>

                  <button
                    className="btn-main btn-2"
                    onClick={() => {
                      GAEventsTracker(
                        "Button/click",
                        `affiliate_learn_more_home_banner`
                      );
                      window.open(urlLeanMore, "_blank", "noopener,noreferrer");
                    }}
                  >
                    <span>Learn more </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6"></div>
        </div>
      </div>
      <div className="second-banner-decor">
        <div className="row">
          <div className="col-lg-6"></div>
          <div className="col-lg-6">
            <Image src={secondslideright} className="desktop optimize-img" />
            <Image src={secondslidemobile} className="mobile optimize-img" />
            <div className="second-banner-img desktop"></div>
          </div>
        </div>
      </div>
    </div>
  );

  const carouselConfig = [mainMarkup];
  return (
    <div>
      <Swiper
        // install Swiper modules
        modules={[Pagination, Autoplay, Navigation]}
        spaceBetween={50}
        slidesPerView={1}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        navigation
        pagination={{ clickable: true }}
        // scrollbar={{ draggable: true }}
        // onSwiper={(swiper) => console.log(swiper)}
        // onSlideChange={() => console.log("slide change")}
      >
        {carouselConfig.map((item, index) => (
          <SwiperSlide key={index}>{item}</SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HeroBanner;
