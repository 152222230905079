import React from "react";

const Lighting = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.75 6.25C8.08696 6.25 7.45107 6.51339 6.98223 6.98223C6.51339 7.45107 6.25 8.08696 6.25 8.75V31.25C6.25 31.913 6.51339 32.5489 6.98223 33.0178C7.45107 33.4866 8.08696 33.75 8.75 33.75H31.25C31.913 33.75 32.5489 33.4866 33.0178 33.0178C33.4866 32.5489 33.75 31.913 33.75 31.25V8.75C33.75 8.08696 33.4866 7.45107 33.0178 6.98223C32.5489 6.51339 31.913 6.25 31.25 6.25H8.75ZM8.75 3.75H31.25C32.5761 3.75 33.8479 4.27678 34.7855 5.21447C35.7232 6.15215 36.25 7.42392 36.25 8.75V31.25C36.25 32.5761 35.7232 33.8479 34.7855 34.7855C33.8479 35.7232 32.5761 36.25 31.25 36.25H8.75C7.42392 36.25 6.15215 35.7232 5.21447 34.7855C4.27678 33.8479 3.75 32.5761 3.75 31.25V8.75C3.75 7.42392 4.27678 6.15215 5.21447 5.21447C6.15215 4.27678 7.42392 3.75 8.75 3.75Z"

            />
            <path
                d="M15 16.25C15.663 16.25 16.2989 15.9866 16.7678 15.5178C17.2366 15.0489 17.5 14.413 17.5 13.75C17.5 13.087 17.2366 12.4511 16.7678 11.9822C16.2989 11.5134 15.663 11.25 15 11.25C14.337 11.25 13.7011 11.5134 13.2322 11.9822C12.7634 12.4511 12.5 13.087 12.5 13.75C12.5 14.413 12.7634 15.0489 13.2322 15.5178C13.7011 15.9866 14.337 16.25 15 16.25ZM15 18.75C13.6739 18.75 12.4021 18.2232 11.4645 17.2855C10.5268 16.3479 10 15.0761 10 13.75C10 12.4239 10.5268 11.1521 11.4645 10.2145C12.4021 9.27678 13.6739 8.75 15 8.75C16.3261 8.75 17.5979 9.27678 18.5355 10.2145C19.4732 11.1521 20 12.4239 20 13.75C20 15.0761 19.4732 16.3479 18.5355 17.2855C17.5979 18.2232 16.3261 18.75 15 18.75Z"

            />
            <path
                d="M18.75 12.5H28.75C29.5833 12.5 30 12.9167 30 13.75C30 14.5833 29.5833 15 28.75 15H18.75C17.9167 15 17.5 14.5833 17.5 13.75C17.5 12.9167 17.9167 12.5 18.75 12.5ZM25 28.75C25.663 28.75 26.2989 28.4866 26.7678 28.0178C27.2366 27.5489 27.5 26.913 27.5 26.25C27.5 25.587 27.2366 24.9511 26.7678 24.4822C26.2989 24.0134 25.663 23.75 25 23.75C24.337 23.75 23.7011 24.0134 23.2322 24.4822C22.7634 24.9511 22.5 25.587 22.5 26.25C22.5 26.913 22.7634 27.5489 23.2322 28.0178C23.7011 28.4866 24.337 28.75 25 28.75ZM25 31.25C23.6739 31.25 22.4021 30.7232 21.4645 29.7855C20.5268 28.8479 20 27.5761 20 26.25C20 24.9239 20.5268 23.6521 21.4645 22.7145C22.4021 21.7768 23.6739 21.25 25 21.25C26.3261 21.25 27.5979 21.7768 28.5355 22.7145C29.4732 23.6521 30 24.9239 30 26.25C30 27.5761 29.4732 28.8479 28.5355 29.7855C27.5979 30.7232 26.3261 31.25 25 31.25Z"

            />
            <path
                d="M11.25 25H21.25C22.0833 25 22.5 25.4167 22.5 26.25C22.5 27.0833 22.0833 27.5 21.25 27.5H11.25C10.4167 27.5 10 27.0833 10 26.25C10 25.4167 10.4167 25 11.25 25Z"

            />
        </svg>
    );
};

export default Lighting;
