import React from "react";

const Ex1 = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.8675 3.33301H6.80087C6.17614 3.33301 5.57699 3.58118 5.13524 4.02293C4.69349 4.46469 4.44531 5.06383 4.44531 5.68856V36.6663H10.0009V33.333H16.6675V36.6663H22.2231V16.2663C21.4242 15.6723 20.8702 14.8066 20.6656 13.8324C20.4609 12.8582 20.6197 11.8427 21.112 10.9775L22.2231 9.14412V5.68856C22.2231 5.06383 21.9749 4.46469 21.5332 4.02293C21.0914 3.58118 20.4923 3.33301 19.8675 3.33301V3.33301ZM10.0009 27.7775H7.77865V25.5552H10.0009V27.7775ZM10.0009 22.2219H7.77865V19.9997H10.0009V22.2219ZM10.0009 16.6663H7.77865V14.4441H10.0009V16.6663ZM10.0009 11.1108H7.77865V8.88856H10.0009V11.1108ZM14.4453 27.7775H12.2231V25.5552H14.4453V27.7775ZM14.4453 22.2219H12.2231V19.9997H14.4453V22.2219ZM14.4453 16.6663H12.2231V14.4441H14.4453V16.6663ZM14.4453 11.1108H12.2231V8.88856H14.4453V11.1108ZM18.8898 27.7775H16.6675V25.5552H18.8898V27.7775ZM18.8898 22.2219H16.6675V19.9997H18.8898V22.2219ZM18.8898 16.6663H16.6675V14.4441H18.8898V16.6663ZM18.8898 11.1108H16.6675V8.88856H18.8898V11.1108Z"
            />
            <path
                d="M24.7009 17.1113H24.4453V36.6669H36.6675V17.1113H24.7009ZM28.8898 27.778H26.6675V25.5558H28.8898V27.778ZM28.8898 22.2224H26.6675V20.0002H28.8898V22.2224ZM33.3342 27.778H31.112V25.5558H33.3342V27.778ZM33.3342 22.2224H31.112V20.0002H33.3342V22.2224Z"
            />
            <path
                d="M29.8329 1.26689L23.4773 12.2225C23.3295 12.4395 23.2446 12.6932 23.2321 12.9555C23.2196 13.2179 23.28 13.4785 23.4065 13.7086C23.5331 13.9387 23.7209 14.1293 23.9491 14.2592C24.1773 14.3891 24.4371 14.4533 24.6996 14.4447H37.4218C37.6842 14.4533 37.944 14.3891 38.1722 14.2592C38.4004 14.1293 38.5882 13.9387 38.7148 13.7086C38.8414 13.4785 38.9017 13.2179 38.8892 12.9555C38.8767 12.6932 38.7919 12.4395 38.644 12.2225L32.2884 1.26689C32.1631 1.05251 31.9838 0.874684 31.7684 0.751104C31.553 0.627524 31.309 0.5625 31.0607 0.5625C30.8123 0.5625 30.5683 0.627524 30.3529 0.751104C30.1375 0.874684 29.9582 1.05251 29.8329 1.26689V1.26689Z"
            />
        </svg>
    );
};

export default Ex1;
