import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Image from "next/future/image";

const Platform = ({ data }) => {
  const platform = data;
  return (
    <div className="container ptb-3rem">
      <div>
        <div className="display-2 text-center auto-rem">{platform.title}</div>
        <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
          <div className="row omg-card omg-ecommerce-card">
            {platform.content.map((item, index) => (
              <div key={index} className="col-md-3">
                <a href={item.href} target="_blank" rel="noopener">
                  <img
                    src={`/images/${item.image}`}
                    alt={item.alt}
                    className="optimize-img"
                  />
                </a>
              </div>
            ))}
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Platform;
