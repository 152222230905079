import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import CircleE from "../SVGS/icons/CircleE";
import Ex1 from "../SVGS/icons/Ex1";
import Ex2 from "../SVGS/icons/Ex2";
import Ex3 from "../SVGS/icons/Ex3";
import Ex4 from "../SVGS/icons/Ex4";
import AmazingLearning from "./AmazingLearning";
import Image from "next/future/image";

const Features = ({ data }) => {
  const listStore = data;
  const knowMore = data.knowMore;
  const icons = [<Ex2 />, <Ex3 />, <Ex1 />, <Ex4 />];
  const [active, setActive] = useState(0);
  return (
    <div className="omg-list-store-wrapper ptb-3rem">
      <div className="container">
        <div className="omg-explore">
          <h2 className="text-center display-2">{knowMore.firstTitle}</h2>
          <h2 className="text-center display-2">{knowMore.secondTitle}</h2>
          <p className="text-center pb-3 body-lg">{knowMore.description}</p>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                {knowMore.content.map((card, index) => (
                  <div
                    className="col-md-6 col-sm-12 omg-explore-content"
                    key={index}
                  >
                    <ScrollAnimation
                      animateIn="fadeIn"
                      duration={2}
                      animateOnce={true}
                    >
                      <div
                        className={
                          active === index
                            ? "small-card explore-active"
                            : "small-card"
                        }
                        onMouseMove={() => setActive(index)}
                      >
                        <div className="svg-effect">
                          <CircleE />
                          {icons[index]}
                        </div>
                        <h3 className="st-h6">{card.title}</h3>
                        <p>{card.text}</p>
                      </div>
                    </ScrollAnimation>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6 img-explore">
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <Image
                  src={`/images/${knowMore.image}`}
                  sizes="(max-width: 768px) 100vw, 636px"
                  width={636}
                  height={423}
                  className="optimize-img"
                  alt="sync track more than just a tool"
                />
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
