import React from "react";

const Ex2 = () => {
    return (
        <svg
            width="40"
            height="36"
            viewBox="0 0 40 36"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27.5 3C27.5 2.33696 27.7634 1.70107 28.2322 1.23223C28.7011 0.763392 29.337 0.5 30 0.5H35C35.663 0.5 36.2989 0.763392 36.7678 1.23223C37.2366 1.70107 37.5 2.33696 37.5 3V33H38.75C39.0815 33 39.3995 33.1317 39.6339 33.3661C39.8683 33.6005 40 33.9185 40 34.25C40 34.5815 39.8683 34.8995 39.6339 35.1339C39.3995 35.3683 39.0815 35.5 38.75 35.5H1.25C0.918479 35.5 0.600537 35.3683 0.366117 35.1339C0.131696 34.8995 0 34.5815 0 34.25C0 33.9185 0.131696 33.6005 0.366117 33.3661C0.600537 33.1317 0.918479 33 1.25 33H2.5V25.5C2.5 24.837 2.76339 24.2011 3.23223 23.7322C3.70107 23.2634 4.33696 23 5 23H10C10.663 23 11.2989 23.2634 11.7678 23.7322C12.2366 24.2011 12.5 24.837 12.5 25.5V33H15V15.5C15 14.837 15.2634 14.2011 15.7322 13.7322C16.2011 13.2634 16.837 13 17.5 13H22.5C23.163 13 23.7989 13.2634 24.2678 13.7322C24.7366 14.2011 25 14.837 25 15.5V33H27.5V3Z"
            />
        </svg>
    );
};

export default Ex2;
