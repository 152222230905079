import React from "react";

const Note = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M31.112 33.3332H6.66753V8.88873H21.3564L23.5786 6.6665H6.66753C6.07816 6.6665 5.51293 6.90063 5.09619 7.31738C4.67944 7.73413 4.44531 8.29936 4.44531 8.88873V33.3332C4.44531 33.9225 4.67944 34.4878 5.09619 34.9045C5.51293 35.3213 6.07816 35.5554 6.66753 35.5554H31.112C31.7013 35.5554 32.2666 35.3213 32.6833 34.9045C33.1001 34.4878 33.3342 33.9225 33.3342 33.3332V16.6665L31.112 18.8887V33.3332Z" />
            <path d="M37.2567 6.48888L33.5123 2.74444C33.3461 2.5778 33.1487 2.4456 32.9313 2.35539C32.7139 2.26518 32.4809 2.21875 32.2456 2.21875C32.0103 2.21875 31.7772 2.26518 31.5599 2.35539C31.3425 2.4456 31.1451 2.5778 30.9789 2.74444L15.7456 18.0667L14.5123 23.4111C14.4597 23.6702 14.4652 23.9377 14.5284 24.1943C14.5916 24.451 14.7109 24.6905 14.8777 24.8956C15.0445 25.1006 15.2547 25.2662 15.4932 25.3803C15.7316 25.4944 15.9924 25.5543 16.2567 25.5555C16.3933 25.5706 16.5312 25.5706 16.6678 25.5555L22.0567 24.3667L37.2567 9.02222C37.4233 8.85604 37.5555 8.65862 37.6458 8.44126C37.736 8.2239 37.7824 7.99088 37.7824 7.75555C37.7824 7.52022 37.736 7.2872 37.6458 7.06984C37.5555 6.85248 37.4233 6.65506 37.2567 6.48888ZM20.9011 22.3111L16.8345 23.2111L17.7789 19.1778L29.2456 7.63333L32.3789 10.7667L20.9011 22.3111ZM33.6345 9.5111L30.5011 6.37777L32.2234 4.62221L35.3789 7.77777L33.6345 9.5111Z" />
        </svg>
    );
};

export default Note;
