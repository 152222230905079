import React from "react";

const Ex3 = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.2344 30.0703L9.82031 27.586C9.15625 26.9922 8.92187 26.0235 9.14843 25.1406C9.38281 24.4453 9.69531 23.5391 10.1406 22.5H1.875C1.20156 22.5 0.579219 22.1406 0.245782 21.5547C0.0819559 21.2674 -0.00282381 20.9418 7.17609e-05 20.6111C0.00296733 20.2804 0.0934348 19.9563 0.262266 19.6719L4.36172 12.7578C5.37422 11.0469 7.21328 9.92972 9.20312 9.92972H15.625C15.8125 9.68753 16 9.39847 16.1875 9.11722C22.5859 -0.317935 32.1172 -0.63606 37.8047 0.412143C38.7187 0.579253 39.4219 1.28363 39.5859 2.19222C40.6328 7.88284 40.3203 17.4141 30.8828 23.8125C30.6094 24 30.2422 24.1875 30 24.3047V30.7969C30 32.7891 28.9531 34.625 27.2422 35.6406L20.3281 39.7344C19.75 40.0781 19.0312 40.086 18.4453 39.7578C17.8594 39.4219 17.5 38.7969 17.5 38.125V29.75C16.3984 30.125 15.4375 30.4453 14.7109 30.6797C13.8359 30.961 12.8828 30.7188 12.2344 30.0703V30.0703ZM30 13.0547C31.7266 13.0547 33.125 11.7266 33.125 9.92972C33.125 8.27347 31.7266 6.80472 30 6.80472C28.2734 6.80472 26.875 8.27347 26.875 9.92972C26.875 11.7266 28.2734 13.0547 30 13.0547Z"
            />
        </svg>
    );
};

export default Ex3;
