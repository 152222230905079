import PieBlue from "../SVGS/icons/PieBlue";
import TagBlue from "../SVGS/icons/TagBlue";
import TwoArrowBlue from "../SVGS/icons/TwoArrowBlue";
import ScrollAnimation from "react-animate-on-scroll";
import Image from "next/future/image";
import imgDecor from "../../public/images/homepage/decor.png";
const HowItWork = ({ data }) => {
  const { title, description, content } = data;
  return (
    <div className="omg-how-it-works ">
      <div className="omg-how-it-works-content">
        <div className="container">
          <div className="row omg-how-it-works-content-title">
            <h2 className="display-2 text-center">Your Synctrack Journey</h2>
            <p className="text-center body-lg">
              Simplified Steps for Streamlined Success
            </p>
          </div>
          <div className="row">
            {content.map((card, index) => (
              <div className="col-md-4" key={index}>
                <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                  <div className="omg-card text-center" key={index}>
                    <img style={{ width: "100%" }} src={card.img} alt="step" />
                    <p className="body-xxl fw-600">{card.step}</p>
                    <div className="divide"></div>
                    <h3 className="st-h5">{card.title}</h3>
                    <p className="body-lg">{card.content}</p>
                  </div>
                </ScrollAnimation>
              </div>
            ))}
          </div>
        </div>
        <div className="omg-how-it-works-decor">
          <Image
            src={imgDecor}
            alt="how synctrack works"
            className="optimize-img"
          />
          <Image
            src={imgDecor}
            alt="how synctrack works"
            className="optimize-img"
          />
        </div>
      </div>
    </div>
  );
};

export default HowItWork;
