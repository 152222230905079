import React from "react";

const ReviewStar = () => {
  return (
    <svg
      width="100"
      height="62"
      viewBox="0 0 457 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.0073 2.91095C57.5731 -0.970317 63.0681 -0.970318 64.6339 2.91095L70.6408 17.8007C70.8602 18.3447 71.3678 18.7182 71.9524 18.7659L87.0409 19.9971C91.132 20.3309 92.8165 25.4142 89.7344 28.125L78.285 38.195C77.8613 38.5677 77.6735 39.1411 77.7948 39.6922L81.456 56.3338C82.3706 60.4908 77.6833 63.5906 74.2159 61.1218L61.2198 51.8685C60.6816 51.4853 59.9596 51.4853 59.4213 51.8685L46.4252 61.1218C42.9579 63.5906 38.2706 60.4908 39.1851 56.3338L42.8464 39.6923C42.9676 39.1411 42.7798 38.5677 42.3561 38.195L30.9063 28.1251C27.8241 25.4143 29.5085 20.3309 33.5997 19.9971L48.6887 18.7659C49.2733 18.7182 49.7809 18.3447 50.0004 17.8007L56.0073 2.91095Z"
        fill="#F3D465"
      />
      <path
        d="M140.007 2.91095C141.573 -0.970317 147.068 -0.970318 148.634 2.91095L154.641 17.8007C154.86 18.3447 155.368 18.7182 155.952 18.7659L171.041 19.9971C175.132 20.3309 176.817 25.4142 173.734 28.125L162.285 38.195C161.861 38.5677 161.674 39.1411 161.795 39.6922L165.456 56.3338C166.371 60.4908 161.683 63.5906 158.216 61.1218L145.22 51.8685C144.682 51.4853 143.96 51.4853 143.421 51.8685L130.425 61.1218C126.958 63.5906 122.271 60.4908 123.185 56.3338L126.846 39.6923C126.968 39.1411 126.78 38.5677 126.356 38.195L114.906 28.1251C111.824 25.4143 113.509 20.3309 117.6 19.9971L132.689 18.7659C133.273 18.7182 133.781 18.3447 134 17.8007L140.007 2.91095Z"
        fill="#F3D465"
      />
      <path
        d="M224.007 2.91095C225.573 -0.970317 231.068 -0.970318 232.634 2.91095L238.641 17.8007C238.86 18.3447 239.368 18.7182 239.952 18.7659L255.041 19.9971C259.132 20.3309 260.817 25.4142 257.734 28.125L246.285 38.195C245.861 38.5677 245.674 39.1411 245.795 39.6922L249.456 56.3338C250.371 60.4908 245.683 63.5906 242.216 61.1218L229.22 51.8685C228.682 51.4853 227.96 51.4853 227.421 51.8685L214.425 61.1218C210.958 63.5906 206.271 60.4908 207.185 56.3338L210.846 39.6923C210.968 39.1411 210.78 38.5677 210.356 38.195L198.906 28.1251C195.824 25.4143 197.509 20.3309 201.6 19.9971L216.689 18.7659C217.273 18.7182 217.781 18.3447 218 17.8007L224.007 2.91095Z"
        fill="#F3D465"
      />
      <path
        d="M308.007 2.91095C309.573 -0.970317 315.068 -0.970318 316.634 2.91095L322.641 17.8007C322.86 18.3447 323.368 18.7182 323.952 18.7659L339.041 19.9971C343.132 20.3309 344.817 25.4142 341.734 28.125L330.285 38.195C329.861 38.5677 329.674 39.1411 329.795 39.6922L333.456 56.3338C334.371 60.4908 329.683 63.5906 326.216 61.1218L313.22 51.8685C312.682 51.4853 311.96 51.4853 311.421 51.8685L298.425 61.1218C294.958 63.5906 290.271 60.4908 291.185 56.3338L294.846 39.6923C294.968 39.1411 294.78 38.5677 294.356 38.195L282.906 28.1251C279.824 25.4143 281.509 20.3309 285.6 19.9971L300.689 18.7659C301.273 18.7182 301.781 18.3447 302 17.8007L308.007 2.91095Z"
        fill="#F3D465"
      />
      <path
        d="M392.007 2.91095C393.573 -0.970317 399.068 -0.970318 400.634 2.91095L406.641 17.8007C406.86 18.3447 407.368 18.7182 407.952 18.7659L423.041 19.9971C427.132 20.3309 428.817 25.4142 425.734 28.125L414.285 38.195C413.861 38.5677 413.674 39.1411 413.795 39.6922L417.456 56.3338C418.371 60.4908 413.683 63.5906 410.216 61.1218L397.22 51.8685C396.682 51.4853 395.96 51.4853 395.421 51.8685L382.425 61.1218C378.958 63.5906 374.271 60.4908 375.185 56.3338L378.846 39.6923C378.968 39.1411 378.78 38.5677 378.356 38.195L366.906 28.1251C363.824 25.4143 365.509 20.3309 369.6 19.9971L384.689 18.7659C385.273 18.7182 385.781 18.3447 386 17.8007L392.007 2.91095Z"
        fill="#F3D465"
      />
    </svg>
  );
};

export default ReviewStar;
