import React from "react";

const Unlimited = () => {
    return (
        <svg
            width="34"
            height="34"
            viewBox="0 0 34 34"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.66536 6.99967H0.332031V30.333C0.332031 31.2171 0.683221 32.0649 1.30834 32.69C1.93346 33.3152 2.78131 33.6663 3.66536 33.6663H26.9987V30.333H3.66536V6.99967ZM27.832 20.7497C27.832 18.2497 22.832 16.9997 20.332 16.9997C17.832 16.9997 12.832 18.2497 12.832 20.7497V21.9997H27.832V20.7497ZM20.332 14.083C22.3987 14.083 24.082 12.3997 24.082 10.333C24.082 8.26634 22.3987 6.58301 20.332 6.58301C18.2654 6.58301 16.582 8.26634 16.582 10.333C16.582 12.3997 18.2654 14.083 20.332 14.083ZM30.332 0.333008H10.332C8.4987 0.333008 6.9987 1.83301 6.9987 3.66634V23.6663C6.9987 24.5504 7.34989 25.3982 7.97501 26.0234C8.60013 26.6485 9.44798 26.9997 10.332 26.9997H30.332C32.182 26.9997 33.6654 25.5163 33.6654 23.6663V3.66634C33.6654 2.78229 33.3142 1.93444 32.6891 1.30932C32.0639 0.684197 31.2161 0.333008 30.332 0.333008ZM30.332 23.6663H10.332V3.66634H30.332V23.6663Z"
            />
        </svg>
    );
};

export default Unlimited;
